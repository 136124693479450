import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/Clubs/EcoClub/2024/ArtLeaf/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/ArtLeaf/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/ArtLeaf/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/ArtLeaf/4.jpg';
// import p5 from 'assests/Photos/Clubs/EcoClub/2024/ArtLeaf/5.jpg';
// import p6 from 'assests/Photos/Clubs/EcoClub/2024/ArtLeaf/6.jpg';


// import p7 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/7.jpg';
// import p8 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/8.jpg';
// import p9 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/9.jpg';
// import p10 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/10.jpg';
// import p11 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/11.jpg';
// import p12 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/12.jpg';
// import p13 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/13.jpg';
// import p14 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/14.jpg';
// import p15 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/15.jpg';
// import p16 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/16.jpg';
// import p17 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/17.jpg';
// import p18 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/18.jpg';


import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import EcoClubSidebar2024 from '../EnvironmentDay2023/EcoClubSidebar2024';
import Sidebar from '../EcoClub/Sidebar';


const ArtLeaf2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EcoClub/ArtLeaf/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EcoClub/ArtLeaf/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EcoClub/ArtLeaf/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EcoClub/ArtLeaf/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/EcoClub/ArtLeaf/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/EcoClub/ArtLeaf/6.webp`;


  const photos = [
    {
        src: p1,
        source: p1,
        rows: 1,
        cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
    },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 1,
    },
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
    }, {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
    }, 
//     {
//         src: p7,
//         source: p7,
//         rows: 1,
//         cols: 1,
//     },
//     {
//         src: p8,
//         source: p8,
//         rows: 1,
//         cols: 1,
//     },
//     {
//         src: p9,
//         source: p9,
//         rows: 1,
//         cols: 1,
//     },
//     {
//       src: p10,
//       source: p10,
//       rows: 1,
//       cols: 1,
//     }, 
//     {
//     src: p11,
//     source: p11,
//     rows: 1,
//     cols: 1,
//    },
//    {
//     src: p12,
//     source: p12,
//     rows: 2,
//     cols: 1,
//    },
//    {
//     src: p13,
//     source: p13,
//     rows: 1,
//     cols: 1,
//    },
//    {
//     src: p14,
//     source: p14,
//     rows: 1.3,
//     cols: 2,
//    }, 
//    {
//     src: p15,
//     source: p15,
//     rows: 1.3,
//     cols: 2,
//    },
//    {
//     src: p16,
//     source: p16,
//     rows: 1.3,
//     cols: 2,
//    }, 
//    {
//     src: p17,
//     source: p17,
//     rows: 1,
//     cols: 1,
//     },

//       {
//         src: p18,
//         source:p18,
//         rows: 1,
//         cols: 1,
//       },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Art-Leaf rubbing
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 1  &  Date: 24 June 2024 to 28 June 2024
        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Time spent among trees is never time wasted. Let’s embark on a Journey into Nature"

        <br/>
        </Typography> */}
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        As part of our Environmental Day awareness program, the students of Class 1 engaged in a unique and creative activity: creating art using leaves. This initiative was designed to help students connect with nature and understand the importance of sustainability in art.
        <br></br>
        Students were encouraged to collect a variety of leaves from their surroundings. They then used these leaves to create beautiful artwork through leaf rubbings and other creative techniques. This hands-on activity also included informative discussions about the different types of leaves and their significance in the environment.
        <br></br>
        The art using leaves activity was a successful part of our Environmental Day celebrations. It not only promoted environmental awareness but also allowed students to explore their creative potential in an eco-friendly manner. It served as a delightful and educational component of our Environmental Day celebrations, leaving a long lasting impact on the young minds.
       
       
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Nature is Our Treasure; let’s take care of it!"

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <EcoClubSidebar2024 />
             </Box>
             <Box marginBottom={4}>
               <Sidebar />
             </Box>


    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default ArtLeaf2024;